// @ts-check
import { Auth } from "aws-amplify";

/**
 * Get current authenticated user cognito groups
 *
 * @returns {Promise<string[]>}
 */
async function getUserGroups() {
  try {
    const authUser = await Auth.currentAuthenticatedUser();
    return (
      authUser.signInUserSession.accessToken.payload["cognito:groups"] || []
    );
  } catch (err) {
    console.error(err);
  }
}

export async function checkAdminStatus() {
  return checkUserBelongsToGroups("admins", "crypto_admins");
}

/**
 * Check if current authenticated user belongs to a cognito group
 *
 * @param {string[]} groups - the group name to check
 * @returns {Promise<boolean>}
 */
export async function checkUserBelongsToGroups(...groups) {
  const userGroups = await getUserGroups();
  return groups.some((group) => userGroups.includes(group));
}
