// @ts-check
import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import Swal from "sweetalert2";
import { API, graphqlOperation } from "aws-amplify";
import { deleteAuditedAccounts } from "graphql/mutations";
import { ViewDetailsButton } from "components/Table/ViewDetailsButton";
import { MonthlyGains } from "./GainsInput/MonthlyGains";

function AuditedAccountsRowTable({
  auditedAccount,
  getAllAuditedAccounts,
  isLast,
  setToEdit,
}) {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const details = {
    ID: auditedAccount.id,
    Nombre: auditedAccount.name,
  };

  if (auditedAccount.url) {
    details.URL = auditedAccount.url;
  }

  if (auditedAccount.gains) {
    details["Ganancias por mes"] =
      "<br>" +
      Object.entries(MonthlyGains.toHumanReadableNames(auditedAccount.gains))
        .map(([key, value]) => `${key} = <i>${value}%</i>`)
        .join("<br>");
  }

  async function deleteAuditedAccountHandler() {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await API.graphql(
            graphqlOperation(deleteAuditedAccounts, {
              input: { id: auditedAccount.id },
            }),
          );
          Swal.fire(
            "Eliminada!",
            "La cuenta auditada ha sido eliminada.",
            "success",
          );
          getAllAuditedAccounts();
        } catch (error) {
          Swal.fire(
            "Error",
            "La cuenta auditada no ha sido eliminada.",
            "error",
          );
        }
      }
    });
  }

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{auditedAccount.name}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <ViewDetailsButton title={auditedAccount.name} info={details} />
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Menu>
          <MenuButton>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList>
            {/* <MenuItem onClick={() => setToEdit(auditedAccount)}>
              Editar
            </MenuItem> */}
            <MenuItem onClick={deleteAuditedAccountHandler}>Eliminar</MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default AuditedAccountsRowTable;
