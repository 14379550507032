// @ts-check
import { Flex } from "@chakra-ui/react";
import { listCryptoSignals } from "graphql/queries";
import useGraphqlOperation from "hooks/useGraphqlOperation";
import { CryptoSignalsCreateForm } from "./CryptoSignalsCreateForm";
import { CryptoSignalsListTable } from "./CryptoSignalsListTable";
import { useState } from "react";

export function CryptoSignalsPage() {
  const { data, updateData, loadingData } =
    useGraphqlOperation(listCryptoSignals);
  const [toEdit, setToEdit] = useState(null);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <CryptoSignalsCreateForm
        updateData={updateData}
        loadingData={loadingData}
        toEdit={toEdit}
        setToEdit={setToEdit}
      />
      <CryptoSignalsListTable
        data={data}
        updateData={updateData}
        setToEdit={setToEdit}
        loading={loadingData}
      />
    </Flex>
  );
}
