/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAuditedAccounts = /* GraphQL */ `
  mutation CreateAuditedAccounts(
    $input: CreateAuditedAccountsInput!
    $condition: ModelAuditedAccountsConditionInput
  ) {
    createAuditedAccounts(input: $input, condition: $condition) {
      id
      name
      gains
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAuditedAccounts = /* GraphQL */ `
  mutation UpdateAuditedAccounts(
    $input: UpdateAuditedAccountsInput!
    $condition: ModelAuditedAccountsConditionInput
  ) {
    updateAuditedAccounts(input: $input, condition: $condition) {
      id
      name
      gains
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAuditedAccounts = /* GraphQL */ `
  mutation DeleteAuditedAccounts(
    $input: DeleteAuditedAccountsInput!
    $condition: ModelAuditedAccountsConditionInput
  ) {
    deleteAuditedAccounts(input: $input, condition: $condition) {
      id
      name
      gains
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCryptoSignal = /* GraphQL */ `
  mutation CreateCryptoSignal(
    $input: CreateCryptoSignalInput!
    $condition: ModelCryptoSignalConditionInput
  ) {
    createCryptoSignal(input: $input, condition: $condition) {
      id
      title
      subtitle
      firstToken
      secondToken
      operation
      position
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCryptoSignal = /* GraphQL */ `
  mutation UpdateCryptoSignal(
    $input: UpdateCryptoSignalInput!
    $condition: ModelCryptoSignalConditionInput
  ) {
    updateCryptoSignal(input: $input, condition: $condition) {
      id
      title
      subtitle
      firstToken
      secondToken
      operation
      position
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCryptoSignal = /* GraphQL */ `
  mutation DeleteCryptoSignal(
    $input: DeleteCryptoSignalInput!
    $condition: ModelCryptoSignalConditionInput
  ) {
    deleteCryptoSignal(input: $input, condition: $condition) {
      id
      title
      subtitle
      firstToken
      secondToken
      operation
      position
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnvivos = /* GraphQL */ `
  mutation CreateEnvivos(
    $input: CreateEnvivosInput!
    $condition: ModelEnvivosConditionInput
  ) {
    createEnvivos(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      untilDate
      envivoDate
      envivoTime
      platform
      url
      coverImage
      scheduleID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnvivos = /* GraphQL */ `
  mutation UpdateEnvivos(
    $input: UpdateEnvivosInput!
    $condition: ModelEnvivosConditionInput
  ) {
    updateEnvivos(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      untilDate
      envivoDate
      envivoTime
      platform
      url
      coverImage
      scheduleID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnvivos = /* GraphQL */ `
  mutation DeleteEnvivos(
    $input: DeleteEnvivosInput!
    $condition: ModelEnvivosConditionInput
  ) {
    deleteEnvivos(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      untilDate
      envivoDate
      envivoTime
      platform
      url
      coverImage
      scheduleID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMotivationalMessage = /* GraphQL */ `
  mutation CreateMotivationalMessage(
    $input: CreateMotivationalMessageInput!
    $condition: ModelMotivationalMessageConditionInput
  ) {
    createMotivationalMessage(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMotivationalMessage = /* GraphQL */ `
  mutation UpdateMotivationalMessage(
    $input: UpdateMotivationalMessageInput!
    $condition: ModelMotivationalMessageConditionInput
  ) {
    updateMotivationalMessage(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMotivationalMessage = /* GraphQL */ `
  mutation DeleteMotivationalMessage(
    $input: DeleteMotivationalMessageInput!
    $condition: ModelMotivationalMessageConditionInput
  ) {
    deleteMotivationalMessage(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNoticia = /* GraphQL */ `
  mutation CreateNoticia(
    $input: CreateNoticiaInput!
    $condition: ModelNoticiaConditionInput
  ) {
    createNoticia(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      timestamp
      optionalImage
      source
      url
      author
      type
      publishedDate
      visibility
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNoticia = /* GraphQL */ `
  mutation UpdateNoticia(
    $input: UpdateNoticiaInput!
    $condition: ModelNoticiaConditionInput
  ) {
    updateNoticia(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      timestamp
      optionalImage
      source
      url
      author
      type
      publishedDate
      visibility
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNoticia = /* GraphQL */ `
  mutation DeleteNoticia(
    $input: DeleteNoticiaInput!
    $condition: ModelNoticiaConditionInput
  ) {
    deleteNoticia(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      timestamp
      optionalImage
      source
      url
      author
      type
      publishedDate
      visibility
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSignal = /* GraphQL */ `
  mutation CreateSignal(
    $input: CreateSignalInput!
    $condition: ModelSignalConditionInput
  ) {
    createSignal(input: $input, condition: $condition) {
      id
      mode
      pair
      operation
      position
      price
      takeProfit
      stopLoss
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSignal = /* GraphQL */ `
  mutation UpdateSignal(
    $input: UpdateSignalInput!
    $condition: ModelSignalConditionInput
  ) {
    updateSignal(input: $input, condition: $condition) {
      id
      mode
      pair
      operation
      position
      price
      takeProfit
      stopLoss
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSignal = /* GraphQL */ `
  mutation DeleteSignal(
    $input: DeleteSignalInput!
    $condition: ModelSignalConditionInput
  ) {
    deleteSignal(input: $input, condition: $condition) {
      id
      mode
      pair
      operation
      position
      price
      takeProfit
      stopLoss
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStrategy = /* GraphQL */ `
  mutation CreateStrategy(
    $input: CreateStrategyInput!
    $condition: ModelStrategyConditionInput
  ) {
    createStrategy(input: $input, condition: $condition) {
      id
      name
      url
      tutorialID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStrategy = /* GraphQL */ `
  mutation UpdateStrategy(
    $input: UpdateStrategyInput!
    $condition: ModelStrategyConditionInput
  ) {
    updateStrategy(input: $input, condition: $condition) {
      id
      name
      url
      tutorialID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStrategy = /* GraphQL */ `
  mutation DeleteStrategy(
    $input: DeleteStrategyInput!
    $condition: ModelStrategyConditionInput
  ) {
    deleteStrategy(input: $input, condition: $condition) {
      id
      name
      url
      tutorialID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTutorial = /* GraphQL */ `
  mutation CreateTutorial(
    $input: CreateTutorialInput!
    $condition: ModelTutorialConditionInput
  ) {
    createTutorial(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      optionalImage
      source
      url
      publishedDate
      coverImage
      bucketID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTutorial = /* GraphQL */ `
  mutation UpdateTutorial(
    $input: UpdateTutorialInput!
    $condition: ModelTutorialConditionInput
  ) {
    updateTutorial(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      optionalImage
      source
      url
      publishedDate
      coverImage
      bucketID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTutorial = /* GraphQL */ `
  mutation DeleteTutorial(
    $input: DeleteTutorialInput!
    $condition: ModelTutorialConditionInput
  ) {
    deleteTutorial(input: $input, condition: $condition) {
      id
      title
      subtitle
      description
      optionalImage
      source
      url
      publishedDate
      coverImage
      bucketID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      username
      phone
      email
      isCommercial
      kyc {
        date
        __typename
      }
      session {
        osName
        deviceName
        deviceModel
        deviceBrand
        activeDate
        __typename
      }
      plan {
        type
        activationDate
        __typename
      }
      dateSigned
      expoToken
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      username
      phone
      email
      isCommercial
      kyc {
        date
        __typename
      }
      session {
        osName
        deviceName
        deviceModel
        deviceBrand
        activeDate
        __typename
      }
      plan {
        type
        activationDate
        __typename
      }
      dateSigned
      expoToken
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      username
      phone
      email
      isCommercial
      kyc {
        date
        __typename
      }
      session {
        osName
        deviceName
        deviceModel
        deviceBrand
        activeDate
        __typename
      }
      plan {
        type
        activationDate
        __typename
      }
      dateSigned
      expoToken
      createdAt
      updatedAt
      __typename
    }
  }
`;
