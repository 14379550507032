// @ts-check
import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import Swal from "sweetalert2";
import { API, graphqlOperation } from "aws-amplify";
import { deleteStrategy, updateStrategy } from "graphql/mutations"; // Asegúrate de que esta mutación esté definida

function TablesTableRowStrategy({
  auditedAccount,
  getAllAuditedAccounts,
  isLast,
}) {
  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function deleteMentalidadFn() {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const mentalidadDetails = { id: auditedAccount.id };
        try {
          await API.graphql(
            graphqlOperation(deleteStrategy, {
              input: mentalidadDetails,
            }),
          );
          Swal.fire(
            "Eliminada!",
            "La estrategia ha sido eliminada.",
            "success",
          );
          getAllAuditedAccounts();
        } catch (error) {
          Swal.fire("Error", "La estrategia no ha sido eliminada.", "error");
          console.log(error);
        }
      }
    });
  }

  async function updateMentalidadFn() {
    const { value: formValues } = await Swal.fire({
      title: "Editar Estrategia",
      html: `<input id="swal-input1" class="swal2-input" placeholder="Nombre" value="${auditedAccount.name}">
      <input id="swal-input2" class="swal2-input" placeholder="URL" value="${auditedAccount.url}">`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
        ];
      },
    });

    if (formValues) {
      const [newName, newUrl] = formValues;

      const strategyDetails = {
        id: auditedAccount.id,
        name: newName,
        url: newUrl,
      };

      try {
        await API.graphql(
          graphqlOperation(updateStrategy, {
            input: strategyDetails,
          }),
        );
        Swal.fire(
          "Actualizada!",
          "La estrategia ha sido actualizada.",
          "success",
        );
        getAllAuditedAccounts();
      } catch (error) {
        Swal.fire("Error", "La estrategia no ha sido actualizada.", "error");
        console.log(error);
      }
    }
  }

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" fontWeight="bold">
          {auditedAccount.id}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{auditedAccount.name}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{auditedAccount.url}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{auditedAccount.tutorialID}</Text>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Menu>
          <MenuButton>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={updateMentalidadFn}>Editar</MenuItem>
            <MenuItem onClick={deleteMentalidadFn}>Eliminar</MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesTableRowStrategy;
