// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const NotificationVisibility = {
  "PUBLIC": "PUBLIC",
  "PRIVATE": "PRIVATE"
};

const SignalOperation = {
  "OPEN": "OPEN",
  "CLOSE": "CLOSE"
};

const SignalPosition = {
  "BUY": "BUY",
  "SELL": "SELL"
};

const SignalMode = {
  "MANUAL": "MANUAL",
  "AUTOMATIC": "AUTOMATIC"
};

const SubscriptionPlanType = {
  "PLAN1": "PLAN1",
  "PLAN2": "PLAN2"
};

const { AuditedAccounts, CryptoSignal, Envivos, MotivationalMessage, Noticia, Signal, Strategy, Tutorial, User, SubscriptionPlan, KYC, Session } = initSchema(schema);

export {
  AuditedAccounts,
  CryptoSignal,
  Envivos,
  MotivationalMessage,
  Noticia,
  Signal,
  Strategy,
  Tutorial,
  User,
  NotificationVisibility,
  SignalOperation,
  SignalPosition,
  SignalMode,
  SubscriptionPlanType,
  SubscriptionPlan,
  KYC,
  Session
};