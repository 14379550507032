// @ts-check
import {
  CardBody,
  Thead,
  Tr,
  Th,
  Tbody,
  useColorModeValue,
  Card,
  Table,
  Button,
} from "@chakra-ui/react";
import AuditedAccountsRowTable from "views/Dashboard/AuditedAccounts/AuditedAccountsRowTable";
import { useEffect, useState } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import { TableHeader } from "components/Table/Table/TableHeader";

// @ts-check
export function AuditedAccountsListTable({ data, update, setToEdit }) {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (data) {
      setAccounts(
        data.listAuditedAccounts?.items?.filter(
          (item) => !!item && !item._deleted,
        ) || [],
      );
    }
  }, [data]);

  return (
    <Card
      overflowX={{ sm: "scroll", xl: "hidden" }}
      my={{ base: "2rem", md: "1rem" }}
      p={{ base: "1rem", md: "2rem" }}
      w={{ base: "100%", md: "90%" }}
      mx="auto"
    >
      <TableHeader
        entity="cuentas auditadas"
        total={accounts.length}
        isFemenine
      />
      <CardBody>
        <Table
          variant="simple"
          color={textColor}
          display={{ base: "block", md: "table" }}
        >
          <Thead display={{ base: "none", md: "table-header-group" }}>
            <div
              style={{
                width: "100%",
                height: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => update()}
                leftIcon={<HiOutlineRefresh />}
                display={{ base: "none", md: "flex" }}
              >
                Actualizar
              </Button>
            </div>
            <Tr my=".8rem" pl="0px">
              <Th color="gray.400" borderColor={borderColor}>
                Nombre
              </Th>
              <Th color="gray.400" borderColor={borderColor} />
              <Th color="gray.400" borderColor={borderColor}>
                Acciones
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {accounts
              .filter((item) => !!item)
              .map((row, index, arr) => {
                return (
                  <AuditedAccountsRowTable
                    auditedAccount={row}
                    getAllAuditedAccounts={update}
                    isLast={index === arr.length - 1}
                    key={index}
                    setToEdit={setToEdit}
                  />
                );
              })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
