// @ts-check
import { FormLabel, Select } from "@chakra-ui/react";
import { ErrorMessage } from "formik";

/**
 * @typedef {Object} FormSelectProps
 * @property {string} label - label of the input
 * @property {string} [placeholder] - placeholder of the input
 * @property {string} name - name of the input
 * @property {import("react").ChangeEventHandler<HTMLSelectElement>} handleChange - function to handle the change of the input
 * @property {string|number} value - value of the input
 * @property {{label: string, value: string}[]} options - options of the select
 */

/**
 * Form select component
 * @param {FormSelectProps} props - select props
 * @returns {React.ReactElement}
 */
export const FormSelect = ({
  label,
  name,
  placeholder,
  handleChange,
  options,
  value,
}) => {
  return (
    <>
      <FormLabel ms="4px" fontSize="sm" fontWeight="bold">
        {label}
      </FormLabel>
      <Select
        variant="auth"
        fontSize="sm"
        ms="4px"
        placeholder={placeholder}
        size="lg"
        id={"form_" + name}
        name={name}
        onChange={handleChange}
        value={value}
        borderWidth={1}
      >
        {!placeholder && <option value="" disabled />}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <ErrorMessage name={name} />
      </div>
    </>
  );
};
