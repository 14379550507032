import {
  Badge,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { FaEllipsisV } from "react-icons/fa";

import Swal from "sweetalert2";

import { ItemContentNewPrincipal } from "components/Menu/ItemContentNewPrincipal.js";
import { Img } from "react-image";
import { Loader } from "@aws-amplify/ui-react";
import { API, graphqlOperation } from "aws-amplify";
import { deleteNoticia, updateNoticia } from "graphql/mutations";
import { ItemContentSignals } from "components/Menu/ItemContentSignals";

function TablesTableRowNews(props) {
  const {
    id,
    title,
    subtitle,
    noticia,
    timestamp,
    type,
    getAllNoticias,
    publishedDate,
    url,
    portada,
    isLast,
  } = props;

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function updateNoticiaFn() {
    const { value: formValues } = await Swal.fire({
      title: "Editar Noticia",
      html:
        `<input id="swal-input1" class="swal2-input" placeholder="Título" value="${title}">` +
        `<input id="swal-input2" class="swal2-input" placeholder="Subtítulo" value="${subtitle}">` +
        `<input id="swal-input3" class="swal2-input" placeholder="Tipo" value="${type}">` +
        `<input id="swal-input4" class="swal2-input" placeholder="URL Externa" value="${url}">` +
        `<input id="swal-input5" class="swal2-input" placeholder="Portada" value="${portada}">` +
        `<input id="swal-input6" class="swal2-input" type="date" placeholder="Fecha de Publicación" value="${publishedDate}">` +
        `<input id="swal-input7" class="swal2-input" placeholder="Descripción" value="${noticia.description}">` +
        `<input id="swal-input8" class="swal2-input" placeholder="Imagen Opcional" value="${noticia.optionalImage}">` +
        `<input id="swal-input9" class="swal2-input" placeholder="Fuente" value="${noticia.source}">` +
        `<input id="swal-input10" class="swal2-input" placeholder="Autor" value="${noticia.author}">`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
          document.getElementById("swal-input3").value,
          document.getElementById("swal-input4").value,
          document.getElementById("swal-input5").value,
          document.getElementById("swal-input6").value,
          document.getElementById("swal-input7").value,
          document.getElementById("swal-input8").value,
          document.getElementById("swal-input9").value,
          document.getElementById("swal-input10").value,
        ];
      },
    });

    if (formValues) {
      const [
        newTitle,
        newSubtitle,
        newType,
        newExternalUrl,
        newPortada,
        newPublishedDate,
        newDescription,
        newOptionalImage,
        newSource,
        newAuthor,
      ] = formValues;

      const noticiaDetails = {
        id: id,
        title: newTitle || title,
        subtitle: newSubtitle || subtitle,
        description: newDescription || noticia.description,
        timestamp: noticia.timestamp,
        optionalImage: newOptionalImage || noticia.optionalImage,
        source: newSource || noticia.source,
        url: newExternalUrl || url,
        author: newAuthor || noticia.author,
        type: newType || type,
        publishedDate: newPublishedDate || publishedDate,
        visibility: noticia.visibility,
        coverImage: newPortada || portada,
      };

      try {
        await API.graphql(
          graphqlOperation(updateNoticia, { input: noticiaDetails }),
        );
        Swal.fire("Actualizada!", "La noticia ha sido actualizada.", "success");
        getAllNoticias();
      } catch (error) {
        Swal.fire("Error", "La noticia no ha sido actualizada.", "error");
        console.log(error);
      }
    }
  }

  async function deleteNoticiaFn() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonColor: "#F56565",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const noticiaDetails = {
          id: id,
          _version: noticia._version,
        };
        API.graphql(graphqlOperation(deleteNoticia, { input: noticiaDetails }))
          .then((data) => {
            Swal.fire(
              "Eliminada!",
              "La noticia ha sido eliminada.",
              "success",
            ).then(async () => {
              getAllNoticias();
            });
          })
          .catch((error) => {
            Swal.fire("Error", "La noticia no ha sido eliminada.", "error");
            console.log(error);
          });
      }
    });
  }

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="wrap">
          <Img
            crossorigin="anonymous"
            style={{ width: "15rem" }}
            borderRadius="100px"
            me="18px"
            src={[portada]}
            loader={<Loader size="lg" />}
          />
          <Flex direction="column" marginLeft={"2"} marginTop={"2"}>
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {title}
            </Text>
            <Text
              fontSize="sm"
              color="gray.400"
              fontWeight="normal"
              noOfLines={2}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {subtitle}
            </Text>
            <Button
              variant="link"
              colorScheme="blue"
              size="sm"
              onClick={() => {
                Swal.fire({
                  title: title,
                  text: subtitle,
                  confirmButtonText: "Ok",
                });
              }}
            >
              Ver más
            </Button>
          </Flex>
        </Flex>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {publishedDate == null ? "N/A" : publishedDate}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Badge
          bg={"blue.400"}
          color={"white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {type}
        </Badge>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {timestamp}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {url}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {portada}
        </Text>
      </Td>

      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Menu>
          <MenuButton marginLeft={"1rem"}>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList p="16px 8px">
            <Flex flexDirection="column">
              <MenuItem borderRadius="8px" mb="10px" onClick={deleteNoticiaFn}>
                <ItemContentNewPrincipal
                  type="delete"
                  onPress={deleteNoticiaFn}
                />
              </MenuItem>
              {/*  <MenuItem borderRadius="8px" mb="10px" onClick={updateNoticiaFn}>
                <ItemContentSignals type="edit" onPress={updateNoticiaFn} />
              </MenuItem> */}
            </Flex>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesTableRowNews;
