// @ts-check
import { monthNames } from "../generateMonthsList";

/** @typedef {Record<string, string>} IntermediateMonthlyGains */
/** @typedef {Record<string, string>} MonthlyGains */

export const MonthlyGains = {
  /**
   * @param {Array<{label: string, value: string}>} months - list of months
   * @returns {IntermediateMonthlyGains}
   */
  initial(months) {
    return {
      [months[0].value]: "",
    };
  },
  /**
   * @param {string} row - month code to be removed
   * @param {IntermediateMonthlyGains} monthlyGains - intermediate monthly gains object
   * @returns {IntermediateMonthlyGains}
   */
  removeRow(row, monthlyGains) {
    const newMonthlyGains = { ...monthlyGains };
    delete newMonthlyGains[row];
    return newMonthlyGains;
  },
  /**
   * @param {string} row - month code to be updated
   * @param {string} newValue - new value
   * @param {IntermediateMonthlyGains} monthlyGains - intermediate monthly gains object
   * @returns {IntermediateMonthlyGains}
   */
  updateRow(row, newValue, monthlyGains) {
    const newMonthlyGains = { ...monthlyGains };
    newMonthlyGains[row] = newValue;
    return newMonthlyGains;
  },
  /**
   * @param {number} last - index of the last month
   * @param {Array<{label: string, value: string}>} months - list of months
   * @returns {number}
   */
  nextMonth(last, months) {
    return last + 1 >= months.length ? 0 : last + 1;
  },
  /**
   * @param {IntermediateMonthlyGains} gains - intermediate monthly gains
   * @returns {string}
   */
  toShortFormat(gains) {
    return Object.keys(gains)
      .map((month) => `${month.slice(2)} ${gains[month] || 0}`)
      .join(",");
  },
  /**
   * @param {string} shortFormat - short format string
   * @param {Array<{label: string, value: string}>} [months] - list of months
   * @returns {MonthlyGains}
   */
  fromShortFormat(shortFormat, months = undefined) {
    if (!shortFormat) return {};
    return shortFormat
      .split(",")
      .map((item) => item.trim())
      .reduce((acc, item) => {
        let [month, gain] = item.split(" ");
        month = month.padStart(4, "0");
        if (months) {
          month = months.find((m) => m.value.slice(1) === month)?.value;
          if (!month) return acc;
        }
        acc[month] = gain;
        return acc;
      }, {});
  },
  /**
   * @param {string} monthCode - month code
   * @returns {string}
   */
  monthCodeToMonthName(monthCode) {
    monthCode = monthCode.slice(-4);
    const month = monthCode.slice(0, -2);
    const year = monthCode.slice(-2);

    const monthName = monthNames[parseInt(month) - 1];
    return `${monthName} 20${year}`;
  },
  /**
   * @param {string} shortFormat - short format string
   * @returns {MonthlyGains}
   */
  toHumanReadableNames(shortFormat) {
    const monthlyGains = MonthlyGains.fromShortFormat(shortFormat);
    /** @type {Record<string, string>} */
    const result = {};
    for (const key in monthlyGains) {
      result[MonthlyGains.monthCodeToMonthName(key)] = monthlyGains[key];
    }
    return result;
  },
};
