// @ts-check
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

export function ItemContentSignals(props) {
  const notificationColor = useColorModeValue("gray.700", "white");

  if (props.type == "delete") {
    return (
      <Flex onClick={props.onPress}>
        <AiFillDelete color={"#F56565"} size={22} />
        <Flex flexDirection="column">
          <Text
            fontSize="14px"
            ml="1"
            color={notificationColor}
            textAlign="center"
            fontWeight={"bold"}
          >
            Eliminar
          </Text>
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex onClick={props.onPress}>
        <AiFillEdit size={22} />
        <Flex flexDirection="column">
          <Text
            fontSize="14px"
            ml="1"
            color={notificationColor}
            textAlign="center"
            fontWeight={"bold"}
          >
            Editar
          </Text>
        </Flex>
      </Flex>
    );
  }
}
