// @ts-check
import { API, graphqlOperation } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";

export default function useGraphqlOperation(operation) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(true);

  const op = useCallback(
    async (input) => {
      setLoading(true);
      try {
        const inputOp = { input: input || {} };
        const result = await API.graphql(graphqlOperation(operation, inputOp));
        setData(result?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [operation],
  );

  useEffect(() => {
    if (!update) return;
    op().finally(() => setUpdate(false));
  }, [update, op]);

  const updateData = () => setUpdate(true);

  return { data, error, loadingData: loading, updateData, op };
}
