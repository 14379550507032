import React, { useState, useEffect, useRef, Suspense } from "react";
import { API, graphqlOperation } from "aws-amplify";
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Text,
  Input,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Card,
  Td,
  Checkbox,
  Badge,
} from "@chakra-ui/react";
// Custom components
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { HiOutlineRefresh } from "react-icons/hi";
import { RiDownload2Fill, RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineClear } from "react-icons/ai";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { listNoticias } from "graphql/queries";
import { createNoticia } from "graphql/mutations";
import { Loader } from "@aws-amplify/ui-react";
import { Img } from "react-image";

import TablesTableRowNews from "components/Table/TablesTableRowNews";
import axios from "axios";

function TablesTravelUsers() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const formRef = useRef();

  const [travelUsers, setTravelUsers] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const [onlyActive, setOnlyActive] = useState(false);

  const getAllTravelUsers = async () => {
    try {
      setIsLoading(true);
      let bodyToSend = {
        Email: "",
        FirstName: "",
        LastName: "",
      };
      console.log("TRAVEL submiting", bodyToSend);

      let bodyStringi = JSON.stringify(bodyToSend);

      const response = await axios.post(
        "https://57tzdc8lt3.execute-api.us-east-1.amazonaws.com/default/searchMembers",
        {
          body: JSON.stringify(bodyToSend),
          headers: {
            "access-control-allow-origin": "*",
          },
        },
      );

      console.log("TRAVEL Response:", response.data.data.data);
      if (response.data.data.data) {
        setTravelUsers(response.data.data.data);
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Algo sucedió!",
        text: "No se pudieron obtener los usuarios de viajes",
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#6b21a8",
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getAllTravelUsers();
  }, []);

  const getTravelUsers = async (payloadToSearch) => {
    try {
      setIsLoading(true);

      console.log("TRAVEL submiting", payloadToSearch);

      const response = await axios.post(
        "https://57tzdc8lt3.execute-api.us-east-1.amazonaws.com/default/searchMembers",
        {
          body: JSON.stringify(payloadToSearch),
          headers: {
            "access-control-allow-origin": "*",
          },
        },
      );

      console.log("TRAVEL Response:", response.data.data.data);
      if (response.data.data.data) {
        setTravelUsers(response.data.data.data);
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Algo sucedió!",
        text: "No se pudieron obtener los usuarios de viajes",
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#6b21a8",
      });
    }

    setIsLoading(false);
  };

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Flex
        direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
        display="flex"
      >
        <Flex style={{ justifyContent: "center", alignItems: "center" }}>
          <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
            style={{ width: "40rem", padding: "1rem" }}
            pb="0px"
          >
            <CardHeader p="0px 0px 0px 0px">
              <Flex direction="column" w={{ sm: "100%" }}>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  pb=".5rem"
                >
                  Buscar usuarios de viajes
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Flex
                style={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Formik
                  id="formik"
                  innerRef={formRef}
                  initialValues={{
                    email: "",
                    firstname: "",
                    lastname: "",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string(),
                    firstname: Yup.string(),
                    lastname: Yup.string(),
                  })}
                  onSubmit={async (values, { resetForm }) => {
                    let payload = {
                      email: values.email.toLowerCase(),
                      FirstName: values.firstname,
                      LastName: values.lastname,
                    };

                    getTravelUsers(payload);
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    values,
                  }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <FormControl>
                          <>
                            <FormLabel ms="4px" fontSize="sm" fontWeight="bold">
                              Email
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Ingrese el email"
                              size="lg"
                              id="form_email"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <ErrorMessage name="email" />
                            </div>
                          </>
                          <>
                            <FormLabel ms="4px" fontSize="sm" fontWeight="bold">
                              Primer nombre
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Ingrese el nombre"
                              size="lg"
                              id="form_firstname"
                              name="firstname"
                              onChange={handleChange}
                              value={values.firstname}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <ErrorMessage name="firstname" />
                            </div>
                          </>
                          <>
                            <FormLabel ms="4px" fontSize="sm" fontWeight="bold">
                              Apellido
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Ingrese el apellido"
                              size="lg"
                              id="form_lastname"
                              name="lastname"
                              onChange={handleChange}
                              value={values.lastname}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <ErrorMessage name="lastname" />
                            </div>
                          </>

                          <Flex style={{ marginBottom: "2rem" }}>
                            <Button
                              fontSize="14px"
                              variant="dark"
                              fontWeight="bold"
                              w="40%"
                              h="45"
                              leftIcon={
                                <AiOutlineClear color="#FFFFFF" size={21} />
                              }
                              backgroundColor={"#3182CE"}
                              color={"white"}
                              onClick={() => {
                                resetForm();
                              }}
                            >
                              RESET
                            </Button>

                            <Button
                              fontSize="14px"
                              variant="dark"
                              fontWeight="bold"
                              w="60%"
                              h="45"
                              disabled={isSubmitting}
                              onClick={handleSubmit}
                              leftIcon={<RiSendPlaneFill />}
                            >
                              BUSCAR USUARIO
                            </Button>
                          </Flex>
                        </FormControl>
                      </form>

                      <Backdrop
                        open={isLoading}
                        style={{
                          zIndex: 20,
                          color: "#fff",
                        }}
                      >
                        <CircularProgress
                          color="inherit"
                          style={{ color: "#03CFB3" }}
                        />
                      </Backdrop>
                    </>
                  )}
                </Formik>
              </Flex>
            </CardBody>
          </Card>
        </Flex>
      </Flex>

      <Card overflowX={{ sm: "scroll" }} my={"2rem"} p={"1rem"}>
        <Checkbox
          onChange={(e) => setOnlyActive(e.target.checked)}
          checked={onlyActive}
        >
          Solo usuarios activos
        </Checkbox>
        <Button
          fontSize="14px"
          variant="dark"
          fontWeight="bold"
          onClick={() => {
            const csvContent =
              "data:text/csv;charset=utf-8," +
              travelUsers
                .map((user) =>
                  Object.values(user)
                    .map((value) => {
                      if (typeof value === "string") {
                        value = value.replace(/"/g, '""');
                      }
                      return `"${value}"`;
                    })
                    .join(","),
                )
                .join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "users.csv");
            document.body.appendChild(link);
            link.click();
          }}
          leftIcon={<RiDownload2Fill />}
        >
          DESCARGAR LISTA .CSV
        </Button>
      </Card>

      <Card overflowX={{ sm: "scroll" }} my={"2rem"} p={"1rem"}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="xl" color={textColor} fontWeight="bold" pb=".5rem">
              Resultados de la busqueda
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex maxWidth={"70vw"} overflowX={"auto"}>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px">
                  <Th color="gray.400" borderColor={borderColor}>
                    Correo
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    Nombre
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    Apellido
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    CreateDate
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    UpdateDate
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    DisabledDate
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    Status
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    Telefono
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    Zip
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    CTS
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    ReferringUserId
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    LoginTokenExpirationTime
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    AddressTwo
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    DateOfBirth
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    City
                  </Th>

                  <Th color="gray.400" borderColor={borderColor}>
                    AddressOne
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    MemberType
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    State
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    Country
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    MemberStatus
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    VoucherPIN
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    UniqueId
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    ReferringMemberId
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    Tier
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    ExternalId
                  </Th>
                  <Th color="gray.400" borderColor={borderColor}>
                    VoucherNumber
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array.isArray(travelUsers)
                  ? travelUsers
                      .filter(
                        (item) =>
                          !!item &&
                          (onlyActive ? item.MemberStatus === "Active" : true),
                      )
                      .map((row, index, arr) => {
                        return (
                          <Tr key={index}>
                            <Td>{row.EmailAddress}</Td>
                            <Td>{row.FirstName}</Td>
                            <Td>{row.LastName}</Td>
                            <Td>{row.CreateDate}</Td>
                            <Td>{row.UpdateDate}</Td>
                            <Td>{row.DisabledDate}</Td>
                            <Td>
                              <Badge
                                bg={
                                  row.MemberStatus === "Active"
                                    ? "green.400"
                                    : "red.400"
                                }
                                color={
                                  row.MemberStatus === "Active"
                                    ? "white"
                                    : "white"
                                }
                                fontSize="16px"
                                p="3px 10px"
                                borderRadius="8px"
                              >
                                {row.MemberStatus === "Active"
                                  ? "Active"
                                  : "Inactive"}
                              </Badge>
                            </Td>
                            <Td>{row.Phone}</Td>
                            <Td>{row.Zip}</Td>
                            <Td>{row.CTS}</Td>
                            <Td>{row.ReferringUserId}</Td>
                            <Td>{row.LoginTokenExpirationTime}</Td>
                            <Td>{row.AddressTwo}</Td>
                            <Td>{row.DateOfBirth}</Td>
                            <Td>{row.City}</Td>
                            <Td>{row.AddressOne}</Td>
                            <Td>{row.MemberType}</Td>
                            <Td>{row.State}</Td>
                            <Td>{row.Country}</Td>
                            <Td>{row.MemberStatus}</Td>
                            <Td>{row.VoucherPIN}</Td>
                            <Td>{row.UniqueId}</Td>
                            <Td>{row.ReferringMemberId}</Td>
                            <Td>{row.Tier}</Td>
                            <Td>{row.ExternalId}</Td>
                            <Td>{row.VoucherNumber}</Td>
                          </Tr>
                        );
                      })
                  : null}
              </Tbody>
            </Table>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default TablesTravelUsers;
