// @ts-check
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Flex,
  FormControl,
  Table,
  Tbody,
  Text,
  Thead,
  useColorModeValue,
  Card,
  FormLabel,
  Checkbox,
} from "@chakra-ui/react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormInput } from "components/Form/FormInput";
import { FormSubmitButtons } from "components/Form/FormSubmitButtons";
import { generateMonthsList } from "views/Dashboard/AuditedAccounts/generateMonthsList";
import { API, graphqlOperation } from "aws-amplify";
import {
  createAuditedAccounts,
  updateAuditedAccounts,
} from "graphql/mutations";
import Swal from "sweetalert2";
import { nanoid } from "nanoid";
import { GainsInput } from "views/Dashboard/AuditedAccounts/GainsInput/GainsInput";
import { MonthlyGains } from "views/Dashboard/AuditedAccounts/GainsInput/MonthlyGains";

/**
 * @typedef {Object} AuditedAccountsCreateFormProps
 * @property {function} updateData - function to update the table data
 * @property {boolean} loadingData - whether the table is loading data
 * @property {import("types/AuditedAccount").AuditedAccount} toEdit - the account to edit
 * @property {function} setToEdit - function to set the account to edit
 */

/**
 * @param {AuditedAccountsCreateFormProps} props
 */
export function AuditedAccountsCreateForm({
  updateData,
  loadingData,
  toEdit,
  setToEdit,
}) {
  const formRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const months = useMemo(() => generateMonthsList(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [monthlyGains, setMonthlyGains] = useState(
    /** @type {Record<string,  string >} */ ({
      [months[0].value]: "",
    }),
  );
  const [lastMonthSelected, setLastMonthSelected] = useState(0);

  useEffect(() => {
    if (toEdit) {
      formRef?.current?.setFieldValue("name", toEdit.name);
      if (toEdit.gains) {
        const gains = MonthlyGains.fromShortFormat(toEdit.gains, months);
        setMonthlyGains(gains);
        const lastMonthSelected = Object.keys(gains).length - 1;
        setLastMonthSelected(lastMonthSelected < 0 ? 0 : lastMonthSelected);
        formRef?.current?.setFieldValue("hasManualGains", true);
      } else {
        formRef?.current?.setFieldValue("url", toEdit.url);
        formRef?.current?.setFieldValue("hasManualGains", false);
      }
    }
  }, [toEdit, months]);

  return (
    <Flex
      direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
      display="flex"
    >
      <Flex style={{ justifyContent: "center", alignItems: "center" }}>
        <Card
          overflowX={{ sm: "scroll", xl: "hidden" }}
          style={{ width: "40rem", padding: "1rem" }}
          pb="0px"
        >
          <CardHeader p="0px 0px 0px 0px">
            <Flex direction="column" w={{ sm: "100%" }}>
              <Text
                fontSize="xl"
                color={textColor}
                fontWeight="bold"
                pb=".5rem"
              >
                {toEdit ? "Editar" : "Crear"} cuenta auditada
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead></Thead>
              <Tbody>
                <Flex
                  style={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Formik
                    innerRef={formRef}
                    initialValues={{
                      name: "",
                      url: "",
                      hasManualGains: false,
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required("Requerido"),
                      url: Yup.string(),
                    })}
                    validate={(values) => {
                      if (!values.hasManualGains && !values.url) {
                        return {
                          url: "Requerido",
                        };
                      }
                      return {};
                    }}
                    onSubmit={async (values, { resetForm }) => {
                      const gains = MonthlyGains.toShortFormat(monthlyGains);
                      try {
                        if (!toEdit) {
                          await API.graphql(
                            graphqlOperation(createAuditedAccounts, {
                              input: {
                                id: nanoid(),
                                name: values.name,
                                url: !values.hasManualGains
                                  ? values.url
                                  : undefined,
                                gains: values.hasManualGains
                                  ? gains
                                  : undefined,
                              },
                            }),
                          );
                        } else {
                          await API.graphql(
                            graphqlOperation(updateAuditedAccounts, {
                              input: {
                                id: toEdit.id,
                                name: values.name,
                                gains,
                              },
                            }),
                          );
                        }
                        Swal.fire({
                          title:
                            "Cuenta auditada " +
                            (toEdit ? "editada" : "creada"),
                          text: "Por favor revisa la app",
                          icon: "success",
                          willClose: () => {
                            resetForm();
                            setMonthlyGains(MonthlyGains.initial(months));
                            updateData();
                            setToEdit(null);
                          },
                        });
                        resetForm();
                      } catch (error) {
                        console.log(error);
                        Swal.fire(
                          "Revisa e intenta de nuevo",
                          error.message,
                          "error",
                        );
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      resetForm,
                      values,
                    }) => (
                      <>
                        <form onSubmit={handleSubmit}>
                          <FormControl>
                            <FormInput
                              label={"Nombre"}
                              placeholder={"Nombre de la cuenta"}
                              name="name"
                              handleChange={handleChange}
                              value={values.name}
                              type="text"
                            />
                            {!values.hasManualGains && (
                              <FormInput
                                label={"URL"}
                                placeholder={
                                  "URL de la cuenta (Ej: https://www.myfxbook.com/members/NOMBRE/NOMBRE-DE-LA-CUENTA/ID-DE-LA-CUENTA)"
                                }
                                name="url"
                                handleChange={handleChange}
                                value={values.url}
                                type="text"
                              />
                            )}
                            <Flex
                              alignItems="center"
                              justifyContent="space-around"
                              height="50px"
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                ¿Es con ganancias manuales?
                              </FormLabel>
                              <Checkbox
                                marginBottom="8px"
                                name="hasManualGains"
                                onChange={handleChange}
                                isChecked={values.hasManualGains}
                              />
                            </Flex>

                            {values.hasManualGains && (
                              <GainsInput
                                months={months}
                                monthlyGains={monthlyGains}
                                setMonthlyGains={setMonthlyGains}
                                lastMonthSelected={lastMonthSelected}
                                setLastMonthSelected={setLastMonthSelected}
                              />
                            )}
                            <FormSubmitButtons
                              okLabel={`${toEdit ? "Editar" : "Crear"} cuenta`}
                              onReset={() => {
                                resetForm();
                                setMonthlyGains(MonthlyGains.initial(months));
                                setLastMonthSelected(0);
                                setToEdit(null);
                              }}
                              disabled={isSubmitting}
                              onSubmit={handleSubmit}
                            />
                          </FormControl>
                        </form>

                        <Backdrop
                          open={isSubmitting || isLoading || loadingData}
                          style={{
                            zIndex: 20,
                            color: "#fff",
                          }}
                        >
                          <CircularProgress
                            color="inherit"
                            style={{ color: "#03CFB3" }}
                          />
                        </Backdrop>
                      </>
                    )}
                  </Formik>
                </Flex>
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Flex>
    </Flex>
  );
}
