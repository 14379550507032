// @ts-check
import { API } from "aws-amplify";
import { MotivationalMessage, Noticia } from "models";

/**
 * Send a notification to the expo server.
 * @param {Object} body - The object with the title, body, and url to send.
 * @param {string} body.title - The title of the notification.
 * @param {string} [body.body] - The body of the notification.
 * @param {string[]} [body.plans] - The plans allowed to receive the notification.
 * @param {string} [body.url] - The url to open when the notification is clicked.
 */
export const sendNotification = async (body) => {
  try {
    await API.post("apiNotifications", "/notifications", {
      body,
    });
  } catch (error) {
    console.error("Error al enviar la notificación:", error);
  }
};

/**
 * @param {Pick<Noticia, "title" | "subtitle" | "url">} news - The signal to send the notification.
 */
export const notificateNews = async (news) => {
  await sendNotification({
    title: `Nueva noticia: ${news.title}`,
    body: news.subtitle,
    url: news.url,
  });
};

/**
 * @param {Pick<Signal, "title" | "pair">} signal - The signal to send the notification.
 */
export const notificateSignal = async (signal) => {
  await sendNotification({
    title: signal.title,
    body: `Nueva señal para el par ${signal.pair}`,
    url: "bmaker://signals",
    plans: ["PLAN2"],
  });
};

/**
 * @param {Pick<Envivo,"title" | "envivoDate" | "envivoTime">} live - The live to send the notification.
 */
export const notificateLive = async (live) => {
  await sendNotification({
    title: `Nuevo en vivo programado: ${live.title}`,
    body: `Nuevo en vivo programado para el día ${live.envivoDate} a las ${live.envivoTime}`,
    url: "bmaker://lives",
    plans: ["PLAN2"],
  });
};

/**
 * @param {Pick<MotivationalMessage, "title">} msg - The motivational message to send the notification.
 */
export const notificateMotivationalMessage = async (msg) => {
  await sendNotification({
    title: msg.title,
    url: "bmaker://motivational",
  });
};
