/**
 * prettyDate returns a pretty formatted date string
 *
 * @param {Date} date - The date to format
 * @returns {string} The formatted date
 */
export const prettyDate = (date) => {
  return date.toLocaleDateString("es-CO", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
};

/**
 * datesComparator returns a function that compares two dates
 *
 * @param {Object} [options] - The options object
 * @param {Function} [options.dateExtractor] - The function to extract the date from the object
 * @param {boolean} [options.desc] - Whether to sort in descending order
 * @returns {(a: any, b: any)=> number} The comparator function
 */
export const datesComparator =
  ({ desc, dateExtractor }) =>
  (a, b) => {
    const dateA = new Date(dateExtractor ? dateExtractor(a) : new Date(a));
    const dateB = new Date(dateExtractor ? dateExtractor(b) : new Date(b));
    return desc ? dateB - dateA : dateA - dateB;
  };
