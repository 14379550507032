import { useState, useEffect, useRef } from "react";
// Chakra imports
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Table,
  Tbody,
  Text,
  Input,
  Thead,
  useColorModeValue,
  Card,
  Spinner,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

// Custom components
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineClear } from "react-icons/ai";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import axios from "axios";
import {
  GetCity,
  GetCountries,
  GetLanguages,
  GetState,
} from "react-country-state-city";

function TablesTravelActivations() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const formRef = useRef();

  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);
  const [language, setLanguage] = useState(0);

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [IsLoadingCountries, setIsLoadingCountries] = useState(false);

  const [IsLoadingCities, setIsLoadingCities] = useState(false);
  const [IsLoadingStates, setIsLoadingStates] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setIsLoadingCountries(true);
    GetCountries().then((result) => {
      setCountryList(result);
      setIsLoadingCountries(false);
    });

    GetLanguages().then((result) => {
      setLanguageList(result);
    });
  }, []);

  const getAllTravelUsers = async () => {
    try {
      setIsLoading(true);
      let bodyToSend = {
        Email: "",
        FirstName: "",
        LastName: "",
      };
      console.log("submiting", bodyToSend);

      let bodyStringi = JSON.stringify(bodyToSend);

      const response = await axios.post(
        "https://f90wkh4vb5.execute-api.us-east-1.amazonaws.com/default/upsertUser",
        {
          body: bodyStringi,
          headers: {
            "access-control-allow-origin": "*",
          },
        },
      );

      console.log("Response:", response.data.data);
      if (response.data.data) {
        setNews(response.data.data);
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Algo sucedió!",
        text: "No se pudieron obtener los usuarios de viajes",
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#3182ce",
      });
    }

    setIsLoading(false);
  };

  /*   useEffect(() => {
    getAllTravelUsers();
  }, []); */

  function toggleVisiblePassword() {
    // Obtener el elemento con id 'passwordInput'
    console.log("changin pas");
    var passwordInput = document.getElementById("passwordInput");
    if (passwordInput) {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        setShowPassword(true);
      } else {
        passwordInput.type = "password";
        setShowPassword(false);
      }
    }
    var confirmpassword = document.getElementById("confirmpassword");
    if (confirmpassword) {
      if (confirmpassword.type === "password") {
        confirmpassword.type = "text";
        setShowPassword(true);
      } else {
        confirmpassword.type = "password";
        setShowPassword(false);
      }
    }
  }

  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 171);

  // Calcula la fecha máxima permitida (debes tener menos de 150 años)
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 21);

  const validationSchema = Yup.object({
    action: Yup.string(),
    email: Yup.string().email("Email inválido"),
    firstName: Yup.string(),
    lastName: Yup.string(),
    password: Yup.string(),

    type: Yup.string(),

    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    country: Yup.string().length(2, "Debe ser un código de país de 2 letras"),
    phone: Yup.string(),
    birthDate: Yup.string()

      .matches(
        /^\d{4}-\d{2}-\d{2}$/,
        "Formato inválido, se requiere (YYYY-MM-DD)",
      ),
  });

  const handleSubmitForm = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log("values:", values);
      setIsLoading(true);

      const bodyToSend = {
        Action: values.action || "",
        Email: values.email.toLowerCase() || "",
        FirstName: values.firstName || "",
        LastName: values.lastName || "",
        Password: values.password || "",
        Address: values.address || "",
        City: values.city || "",
        State: values.state || "",
        Zip: values.zip || "",
        Country: values.country || "",
        Phone: values.phone || "",
        BirthDate: values.birthDate || "",
        Type: values.type || "",
        ExternalId: values.externalId || "",
        Tier: values.tier || "",
        Points: values.points || "",
      };

      console.log("bodyToSend:", bodyToSend);

      const response = await axios.post(
        "https://f90wkh4vb5.execute-api.us-east-1.amazonaws.com/default/upsertUser",
        { body: JSON.stringify(bodyToSend) },
        { headers: { "access-control-allow-origin": "*" } },
      );

      console.log("response:", response);

      if (response.data.data.message === "MalformedRequest") {
        throw new Error();
      }

      Swal.fire({
        title: "Actualización exitosa!",
        text: "Ahora el usuario puede iniciar sesión",
        icon: "success",
        showConfirmButton: true,
        confirmButtonColor: "#3182ce",
      }).then((event) => {
        resetForm();
        const divElement = document.getElementById("componentToScrollTo");
        if (divElement) {
          divElement.scrollIntoView({ behavior: "smooth" });
        }
      });
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Algo sucedió!",
        text: "No se pudo completar el registro, revisa los datos e intentelo de nuevo!",
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: "#3182ce",
      });
    } finally {
      console.log("Finalizó el submit");
      setIsLoading(false);
    }
  };

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Flex
        direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
        display="flex"
      >
        <Flex style={{ justifyContent: "center", alignItems: "center" }}>
          <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
            style={{ width: "40rem", padding: "1rem" }}
            pb="0px"
          >
            <CardHeader p="0px 0px 0px 0px">
              <Flex direction="column" w={{ sm: "100%" }}>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  pb=".5rem"
                >
                  Activar / Desactivar Usuario
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Table variant="simple" color={textColor}>
                <Thead></Thead>
                <Tbody>
                  <Flex
                    style={{
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Formik
                      id="formik"
                      innerRef={formRef}
                      initialValues={{
                        action: "",
                        email: "",
                        firstName: "",
                        lastName: "",
                        password: "",
                        address: "",
                        city: "",
                        state: "",
                        zip: "",
                        country: "",
                        phone: "",
                        birthDate: "",
                        type: "",
                        externalId: "",
                        tier: "",
                        points: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmitForm}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        values,
                        setFieldValue,
                      }) => (
                        <>
                          <form onSubmit={handleSubmit}>
                            <FormControl>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  ¿Qué desea hacer?
                                </FormLabel>
                                <Select
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Seleccione un plan"
                                  size="lg"
                                  id="form_action"
                                  name="action"
                                  onChange={handleChange}
                                  value={values.action}
                                  border={true}
                                  borderWidth={1}
                                >
                                  <option value="upsert">Activar plan</option>
                                  <option value="disable">
                                    Desactivar plan
                                  </option>
                                </Select>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="action" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Email
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Ingresa el email"
                                  size="lg"
                                  id="email"
                                  name="email"
                                  type="email"
                                  onChange={handleChange}
                                  value={values.email}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="email" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Nombre
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Ingresa el nombre"
                                  size="lg"
                                  id="firstName"
                                  name="firstName"
                                  type="text"
                                  onChange={handleChange}
                                  value={values.firstName}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="firstName" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Apellidos
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Ingresa el apellido"
                                  size="lg"
                                  id="lastName"
                                  name="lastName"
                                  type="text"
                                  onChange={handleChange}
                                  value={values.lastName}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="lastName" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Contraseña
                                </FormLabel>
                                <Flex>
                                  <Input
                                    variant="auth"
                                    fontSize="sm"
                                    ms="4px"
                                    placeholder="Ingresa la clave"
                                    size="lg"
                                    id="passwordInput"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    onChange={handleChange}
                                    value={values.password}
                                  />
                                  <div>
                                    <button
                                      className="rounded flex justify-center align-center"
                                      type="button"
                                      onClick={() => toggleVisiblePassword()}
                                    >
                                      {!showPassword ? (
                                        <HiEye color="black" size={"30px"} />
                                      ) : (
                                        <HiEyeOff color="black" size={"30px"} />
                                      )}{" "}
                                    </button>
                                  </div>
                                </Flex>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="password" />
                                </div>
                              </>

                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Dirección
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Ingresa la dirección"
                                  size="lg"
                                  id="address"
                                  name="address"
                                  type="text"
                                  onChange={handleChange}
                                  value={values.address}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="address" />
                                </div>
                              </>

                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Código postal
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Ingresa el código postal"
                                  size="lg"
                                  id="zip"
                                  name="zip"
                                  type="text"
                                  onChange={handleChange}
                                  value={values.zip}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="zip" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  País{" "}
                                </FormLabel>

                                {IsLoadingCountries ? (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="flex justify-center align-center"
                                  >
                                    <div role="status">
                                      <Spinner />
                                    </div>
                                  </motion.div>
                                ) : (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                  >
                                    <Select
                                      variant="auth"
                                      fontSize="sm"
                                      ms="4px"
                                      type="text"
                                      size="lg"
                                      id="form_type"
                                      name="type"
                                      onChange={(e) => {
                                        if (
                                          e.target.value !=
                                          "Seleccione una opción"
                                        ) {
                                          setIsLoadingStates(true);
                                          const country =
                                            countryList[e.target.value];

                                          // Convertir e.target.value a número
                                          const selectedId = parseInt(
                                            e.target.value,
                                            10,
                                          );
                                          // Buscar el estado en cityList que coincida con el ID seleccionado
                                          let selectedCountry =
                                            countryList.find(
                                              (element) =>
                                                element.id === selectedId,
                                            );
                                          if (
                                            selectedCountry &&
                                            selectedCountry.iso2
                                          ) {
                                            setFieldValue(
                                              "country",
                                              selectedCountry.iso2,
                                            );
                                          }

                                          setFieldValue("state", "");
                                          setFieldValue("city", "");

                                          setCountryid(selectedId);
                                          setStateid(0);
                                          setCityid(0);
                                          setCityList([]);
                                          GetState(selectedId).then(
                                            (result) => {
                                              setStateList(result);
                                              setIsLoadingStates(false);
                                            },
                                          );
                                        }
                                      }}
                                      value={countryid}
                                      defaultValue={"default"}
                                      border={true}
                                      borderWidth={1}
                                    >
                                      <option
                                        key={"default"}
                                        value={"Seleccione una opción"}
                                      >
                                        Seleccione una opción
                                      </option>
                                      {countryList.map((item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </motion.div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="country" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Estado / Region / Departamento
                                </FormLabel>

                                {IsLoadingStates ? (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="flex justify-center align-center"
                                  >
                                    <div role="status">
                                      <Spinner />
                                    </div>
                                  </motion.div>
                                ) : (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                  >
                                    <Select
                                      variant="auth"
                                      fontSize="sm"
                                      ms="4px"
                                      type="text"
                                      size="lg"
                                      id="form_type"
                                      name="type"
                                      onChange={(e) => {
                                        if (
                                          e.target.value !=
                                          "Seleccione una opción"
                                        ) {
                                          setIsLoadingCities(true);
                                          // Convertir e.target.value a número
                                          const selectedId = parseInt(
                                            e.target.value,
                                            10,
                                          );
                                          // Buscar el estado en stateList que coincida con el ID seleccionado
                                          let selectedState = stateList.find(
                                            (element) =>
                                              element.id === selectedId,
                                          );
                                          setStateid(selectedId);
                                          setCityid(0);
                                          setFieldValue(
                                            "state",
                                            selectedState.name,
                                          );
                                          setFieldValue("city", "");

                                          GetCity(countryid, selectedId).then(
                                            (result) => {
                                              setCityList(result);
                                              setIsLoadingCities(false);
                                            },
                                          );
                                        }
                                      }}
                                      value={stateid}
                                      defaultValue={"default"}
                                      border={true}
                                      borderWidth={1}
                                    >
                                      <option
                                        key={"default"}
                                        value={"Seleccione una opción"}
                                      >
                                        Seleccione una opción
                                      </option>
                                      {stateList.map((item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </motion.div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="state" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Ciudad
                                </FormLabel>
                                {IsLoadingStates ? (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="flex justify-center align-center"
                                  >
                                    <div role="status">
                                      <Spinner />
                                    </div>
                                  </motion.div>
                                ) : (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                  >
                                    <Select
                                      variant="auth"
                                      fontSize="sm"
                                      ms="4px"
                                      type="text"
                                      size="lg"
                                      id="form_type"
                                      name="city"
                                      onChange={(e) => {
                                        if (
                                          e.target.value !=
                                          "Seleccione una opción"
                                        ) {
                                          // Convertir e.target.value a número
                                          const selectedId = parseInt(
                                            e.target.value,
                                            10,
                                          );
                                          // Buscar el estado en cityList que coincida con el ID seleccionado
                                          let selectedCity = cityList.find(
                                            (element) =>
                                              element.id === selectedId,
                                          );

                                          setCityid(selectedId);
                                          setFieldValue(
                                            "city",
                                            selectedCity.name,
                                          );
                                        }
                                      }}
                                      value={cityid}
                                      defaultValue={"default"}
                                      border={true}
                                      borderWidth={1}
                                    >
                                      <option
                                        key={"default"}
                                        value={"Seleccione una opción"}
                                      >
                                        Seleccione una opción
                                      </option>
                                      {cityList.map((item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </motion.div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="city" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Teléfono
                                </FormLabel>
                                <div
                                  style={{
                                    borderWidth: "1px",
                                    borderColor: "#e2e8f0",
                                    padding: "0.8rem",
                                    borderRadius: "6px",
                                  }}
                                >
                                  <PhoneInput
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    className="w-full p-3 text-black "
                                    onChange={(value) =>
                                      setFieldValue("phone", value)
                                    }
                                    value={values.phone}
                                    placeholder="Ingresa el teléfono"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="phone" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Plan de Viajes
                                </FormLabel>
                                <Select
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Seleccione un plan"
                                  size="lg"
                                  id="form_type"
                                  name="type"
                                  onChange={handleChange}
                                  value={values.plan}
                                  border={true}
                                  borderWidth={1}
                                >
                                  <option value="Premium">PREMIUM</option>
                                  {/*                                   <option value="Basic">BÁSICO</option>
                                   */}{" "}
                                </Select>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="type" />
                                </div>
                              </>

                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Fecha de Nacimiento
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="date"
                                  placeholder="Fecha de Nacimiento"
                                  size="lg"
                                  id="birthDate"
                                  name="birthDate"
                                  onChange={handleChange}
                                  min={minDate} // Atributo min para el límite inferior de la fecha
                                  max={maxDate} // Atributo max para el límite superior de la fecha
                                  defaultValue={""}
                                  value={values.publishedDate}
                                  style={{ marginBottom: "1rem" }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="birthDate" />
                                </div>
                              </>

                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  External ID (opcional)
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Ingresa el ID Externo"
                                  size="lg"
                                  id="externalId"
                                  name="externalId"
                                  type="text"
                                  onChange={handleChange}
                                  value={values.externalId}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="externalId" />
                                </div>
                              </>

                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Puntos (opcional)
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Ingresa los puntos"
                                  size="lg"
                                  id="points"
                                  name="points"
                                  type="number"
                                  onChange={handleChange}
                                  value={values.points}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="points" />
                                </div>
                              </>

                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Tier (opcional)
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Ingresa el tier"
                                  size="lg"
                                  id="tier"
                                  name="tier"
                                  type="number"
                                  onChange={handleChange}
                                  value={values.tier}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="tier" />
                                </div>
                              </>

                              <Flex style={{ marginBottom: "2rem" }}>
                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="40%"
                                  h="45"
                                  leftIcon={
                                    <AiOutlineClear color="#FFFFFF" size={21} />
                                  }
                                  backgroundColor={"#3182CE"}
                                  color={"white"}
                                  onClick={() => {
                                    resetForm();
                                  }}
                                >
                                  RESET
                                </Button>

                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="60%"
                                  h="45"
                                  disabled={isSubmitting}
                                  onClick={handleSubmit}
                                  leftIcon={<RiSendPlaneFill />}
                                >
                                  ACTUALIZAR USUARIO
                                </Button>
                              </Flex>
                            </FormControl>
                          </form>

                          <Backdrop
                            open={isLoading}
                            style={{
                              zIndex: 20,
                              color: "#fff",
                            }}
                          >
                            <CircularProgress
                              color="inherit"
                              style={{ color: "#03CFB3" }}
                            />
                          </Backdrop>
                        </>
                      )}
                    </Formik>
                  </Flex>
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TablesTravelActivations;
