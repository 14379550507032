// @ts-check
import { Flex, Button } from "@chakra-ui/react";
import { AiOutlineClear } from "react-icons/ai";
import { RiSendPlaneFill } from "react-icons/ri";

export function FormSubmitButtons({ okLabel, onReset, disabled, onSubmit }) {
  return (
    <Flex style={{ marginBottom: "2rem" }}>
      <Button
        fontSize="14px"
        variant="dark"
        fontWeight="bold"
        w="40%"
        h="45"
        leftIcon={<AiOutlineClear color="#FFFFFF" size={21} />}
        backgroundColor={"#3182CE"}
        color={"white"}
        onClick={() => onReset()}
      >
        RESET
      </Button>
      <Button
        fontSize="14px"
        variant="dark"
        fontWeight="bold"
        w="60%"
        h="45"
        disabled={disabled}
        // @ts-ignore
        onClick={onSubmit}
        leftIcon={<RiSendPlaneFill />}
      >
        {okLabel.toUpperCase()}
      </Button>
    </Flex>
  );
}
