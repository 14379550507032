// @ts-check
import { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { CryptoSignalsListTableRow } from "views/Dashboard/CryptoSignals/CryptoSignalsListTableRow";
import { Loader } from "components/Loader";
import { Table } from "components/Table/Table";
import { datesComparator } from "utils/Dates";

export function CryptoSignalsListTable({
  data,
  updateData,
  setToEdit,
  loading,
}) {
  const [cryptoSignals, setCryptoSignals] = useState([]);

  useEffect(() => {
    if (loading) return;
    if (data) {
      setCryptoSignals(
        data.listCryptoSignals?.items?.filter(
          (item) => !!item && !item._deleted,
        ) || [],
      );
    }
  }, [data, loading]);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Table
        columns={[
          "Par Operado",
          "Precio",
          "Hora",
          "Fecha",
          "Tipo de Orden",
          "Posicion",
        ]}
        items={cryptoSignals.sort(
          datesComparator({
            dateExtractor: (signal) => signal.createdAt,
            desc: true,
          }),
        )}
        setToEdit={setToEdit}
        RowComponent={CryptoSignalsListTableRow}
        entity="señales crypto"
        isFemenine
        updateData={() => updateData()}
      />
      <Loader loading={loading} />
    </Flex>
  );
}
