// @ts-check
import {
  Badge,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import Swal from "sweetalert2";
import { API, graphqlOperation } from "aws-amplify";
import {
  deleteMotivationalMessage,
  updateMotivationalMessage,
} from "graphql/mutations"; // Asegúrate de que esta mutación esté definida

function TablesTableRowMentalidad(props) {
  const { id, title, getAllMentalidads, mentalidad, timestamp, isLast } = props;

  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function deleteMentalidadFn() {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const mentalidadDetails = { id: id };
        try {
          await API.graphql(
            graphqlOperation(deleteMotivationalMessage, {
              input: mentalidadDetails,
            }),
          );
          Swal.fire(
            "Eliminado!",
            "El mensaje de salud mental ha sido eliminado.",
            "success",
          );
          getAllMentalidads();
        } catch (error) {
          Swal.fire(
            "Error",
            "El mensaje de salud mental no ha sido eliminado.",
            "error",
          );
          console.log(error);
        }
      }
    });
  }

  async function updateMentalidadFn() {
    const { value: formValues } = await Swal.fire({
      title: "Editar Mensaje de Salud Mental",
      html: `<input id="swal-input1" class="swal2-input" placeholder="Mensaje" value="${title}">`,
      focusConfirm: false,
      preConfirm: () => {
        return [document.getElementById("swal-input1").value];
      },
    });

    if (formValues) {
      const [newTitle] = formValues;

      const mentalidadDetails = {
        id: id,
        title: newTitle,
      };

      try {
        await API.graphql(
          graphqlOperation(updateMotivationalMessage, {
            input: mentalidadDetails,
          }),
        );
        Swal.fire(
          "Actualizado!",
          "El mensaje de salud mental ha sido actualizado.",
          "success",
        );
        getAllMentalidads();
      } catch (error) {
        Swal.fire(
          "Error",
          "El mensaje de salud mental no ha sido actualizado.",
          "error",
        );
        console.log(error);
      }
    }
  }

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" fontWeight="bold">
          {id}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{title}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Badge colorScheme="blue">{timestamp}</Badge>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Menu>
          <MenuButton>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={deleteMentalidadFn}>Eliminar</MenuItem>
            <MenuItem onClick={updateMentalidadFn}>Editar</MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesTableRowMentalidad;
