// @ts-check
import { FormLabel, Input } from "@chakra-ui/react";
import { PriceValueObject } from "@juandardilag/value-objects/PriceValueObject";
import { ErrorMessage } from "formik";
import { useEffect } from "react";

/**
 * @typedef {Object} FormInputProps
 * @property {string} label - label of the input
 * @property {string} placeholder - placeholder of the input
 * @property {"text"| "number" | "currency"} [type] - type of the input
 * @property {string} name - name of the input
 * @property {import("react").ChangeEventHandler<HTMLInputElement>} handleChange - function to handle the change of the input
 * @property {(value: string | number) => void} [onInputChange] - function to handle the change of the input
 * @property {string|number} value - value of the input
 * @property {Object} [style] - style of the input
 * @property {boolean} [disabled] - indicates if the input is disabled
 * @property {string} [key] - key of the input
 */

/**
 * Form input component
 * @param {FormInputProps} props - input props
 * @returns {React.ReactElement}
 */
export function FormInput({
  label,
  placeholder,
  type,
  name,
  onInputChange,
  handleChange,
  value,
  style,
  disabled,
  key,
}) {
  useEffect(() => {
    const input = document.getElementById("input" + name);
    if (input && type === "currency") {
      PriceValueObject.parseInput(input, (priceStr) => {
        onInputChange && onInputChange(priceStr);
      });
    }
  }, [key, name, type, onInputChange]);

  return (
    <div style={style} key={key || name}>
      <FormLabel ms="4px" fontSize="sm" fontWeight="bold">
        {label}
      </FormLabel>
      <Input
        id={"input" + name}
        disabled={disabled}
        variant="auth"
        fontSize="sm"
        ms="4px"
        type="text"
        placeholder={placeholder}
        name={name}
        onChange={(e) => {
          if (type === "number") {
            if (e.target.value !== "-") {
              e.target.value = (
                isNaN(parseFloat(e.target.value))
                  ? ""
                  : parseFloat(e.target.value)
              ).toString();
            }
          }
          handleChange(e);
        }}
        value={value}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <ErrorMessage name={name} />
      </div>
    </div>
  );
}
