// @ts-check
import "antd/dist/antd.css";
import "./style.css";
import Dashboard from "views/Dashboard/Dashboard";
import UsersListTable from "views/Dashboard/Users/UsersListTable";
import { HomeIcon } from "components/Icons/Icons";
import { HiUsers } from "react-icons/hi";
import { GiBookCover } from "react-icons/gi";
import { FaBitcoin, FaPlane, FaUser } from "react-icons/fa";
import {
  BiBarChartAlt,
  BiBarChartSquare,
  BiMessageSquare,
  BiSolidVideoRecording,
} from "react-icons/bi";
import { BiNews } from "react-icons/bi";
import TablesNews from "views/Dashboard/News/TablesNews";
import { LivesListTable } from "views/Dashboard/Lives/LivesListTable";
import TablesTravelUsers from "views/Dashboard/Travels/TablesTravelUsers";
import TablesTravelActivations from "views/Dashboard/Travels/TablesTravelActivations";
import TablesMentalidad from "views/Dashboard/MotivationalMessages/TablesMentalidad";
import TablesStrategy from "views/Dashboard/SocialStrategies/TablesStrategy";
import { TutorialsPage } from "views/Dashboard/Tutorials/TutorialsPage";
import { AuditedAccountsPage } from "views/Dashboard/AuditedAccounts/AuditedAccountsPage";
import { CryptoSignalsPage } from "views/Dashboard/CryptoSignals/CryptoSignalsPage";

export default function routes(isSuperAdmin) {
  /** @type {Array} */
  const routes = [
    {
      path: "/dashboard",
      name: "Home",
      icon: <HomeIcon color="inherit" />,
      component: Dashboard,
      layout: "/admin",
    },
  ];

  if (isSuperAdmin) {
    routes.push(...superAdminRoutes);
  }
  routes.push(
    {
      name: "NOTICIAS",
      category: "news",
      state: "pageCollapse",
      views: [
        {
          path: "/all-news",
          name: "Todas",
          icon: <BiNews color="inherit" />,
          component: TablesNews,
          layout: "/admin",
        },
      ],
    },
    {
      name: "EN VIVOS",
      category: "lives",
      state: "pageCollapse",
      views: [
        {
          path: "/all-lives",
          name: "Todos",
          icon: <BiSolidVideoRecording color="inherit" />,
          component: LivesListTable,
          layout: "/admin",
        },
      ],
    },
  );

  return routes;
}

const superAdminRoutes = [
  {
    name: "USUARIOS",
    category: "users",
    state: "pageCollapse",
    views: [
      {
        path: "/users",
        name: "Todos",
        icon: <HiUsers color="inherit" />,
        component: UsersListTable,
        layout: "/admin",
      },
      {
        name: "SEÑALES",
        category: "signals",
        state: "pageCollapse",
        views: [
          // REVIEW: commented out for now
          // {
          //   path: "/manual-mode",
          //   name: "Fórex",
          //   icon: <FaChartLine color="inherit" />,
          //   component: ForexSignalsTable,
          //   layout: "/admin",
          // },
          {
            path: "/crypto-signals",
            name: "Crypto",
            icon: <FaBitcoin color="inherit" />,
            component: CryptoSignalsPage,
            layout: "/admin",
          },
        ],
      },
      {
        name: "VIAJES",
        category: "travels",
        state: "pageCollapse",
        views: [
          {
            path: "/all-travel-activations",
            name: "Activar Usuario",
            icon: <FaPlane color="inherit" />,
            component: TablesTravelActivations,
            layout: "/admin",
          },
          {
            path: "/all-travel-users",
            name: "Buscar Usuarios",
            icon: <FaUser color="inherit" />,
            component: TablesTravelUsers,
            layout: "/admin",
          },
        ],
      },

      {
        name: "TUTORIALES",
        category: "tutorials",
        state: "pageCollapse",
        views: [
          {
            path: "/tutorials",
            name: "Todos",
            icon: <GiBookCover color="inherit" />,
            component: TutorialsPage,
            layout: "/admin",
          },
        ],
      },
      {
        name: "SOCIAL TRADING",
        category: "social-trading",
        state: "pageCollapse",
        views: [
          {
            path: "/estrategias",
            name: "Estrategias",
            icon: <BiBarChartAlt color="inherit" />,
            component: TablesStrategy,
            layout: "/admin",
          },
        ],
      },
      {
        name: "CUENTAS AUDITADAS",
        category: "audited-accounts",
        state: "pageCollapse",
        views: [
          {
            path: "/cuentas-auditadas",
            name: "Todas",
            icon: <BiBarChartSquare color="inherit" />,
            component: AuditedAccountsPage,
            layout: "/admin",
          },
        ],
      },
      {
        name: "SALUD MENTAL",
        category: "mentalidad",
        state: "pageCollapse",
        views: [
          {
            path: "/mensajes-mentalidad",
            name: "Mensajes",
            icon: <BiMessageSquare color="inherit" />,
            component: TablesMentalidad,
            layout: "/admin",
          },
        ],
      },
    ],
  },
];
