import { Backdrop, CircularProgress } from "@material-ui/core";

export const Loader = ({ loading }) => {
  return (
    <Backdrop
      open={loading}
      style={{
        zIndex: 20,
        color: "#fff",
      }}
    >
      <CircularProgress color="inherit" style={{ color: "#03CFB3" }} />
    </Backdrop>
  );
};
