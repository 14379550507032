// @ts-check
import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Card,
} from "@chakra-ui/react";
import CardBody from "components/Card/CardBody.js";
import { HiOutlineRefresh } from "react-icons/hi";
import { TutorialsListTableRow } from "./TutorialsTableRow";
import { TableHeader } from "components/Table/Table/TableHeader";
import { datesComparator } from "utils/Dates";

export function TutorialsListTable({ s3Client, data, updateData }) {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [tutorials, setTutorials] = useState([]);

  useEffect(() => {
    if (data) {
      setTutorials(
        data.listTutorials?.items?.filter((item) => !!item && !item._deleted) ||
          [],
      );
    }
  }, [data]);

  return (
    <Card
      overflowX={{ sm: "scroll", xl: "scroll" }}
      my={{ base: "2rem", md: "1rem" }}
      p={{ base: "1rem", md: "2rem" }}
      w={{ base: "100%", md: "90%" }}
      mx="auto"
    >
      <TableHeader entity="tutoriales" total={tutorials.length} />
      <CardBody>
        <Table
          variant="simple"
          color={textColor}
          display={{ base: "block", md: "table" }}
        >
          <Thead display={{ base: "none", md: "table-header-group" }}>
            <div
              style={{
                width: "100%",
                height: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => updateData()}
                leftIcon={<HiOutlineRefresh />}
                display={{ base: "none", md: "flex" }}
              >
                Actualizar
              </Button>
            </div>
            <Tr my=".8rem" pl="0px">
              <Th color="gray.400" borderColor={borderColor}>
                ID
              </Th>
              <Th color="gray.400" borderColor={borderColor}>
                Titulo/Subtitulo/Portada
              </Th>
              <Th color="gray.400" borderColor={borderColor}>
                Fecha Publicación
              </Th>
              <Th color="gray.400" borderColor={borderColor}>
                URL Portada
              </Th>
              <Th color="gray.400" borderColor={borderColor}>
                Acciones
              </Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody display={{ base: "block", md: "table-row-group" }}>
            {tutorials
              .sort(
                datesComparator({
                  desc: true,
                  dateExtractor: (tutorial) => tutorial.createdAt,
                }),
              )
              .map((row, index, arr) => {
                return (
                  <TutorialsListTableRow
                    tutorial={row}
                    isLast={index === arr.length - 1}
                    updateData={updateData}
                    s3Client={s3Client}
                    key={index}
                  />
                );
              })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
