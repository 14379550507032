// @ts-check
import { useState, useEffect } from "react";
// Chakra Icons
// Chakra Imports
import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/profile.png";
import avatar2 from "assets/img/avatars/settings.png";
import avatar3 from "assets/img/avatars/logout.png";

import { Auth } from "aws-amplify";

// Custom Icons
import { ArgonLogoDark, ArgonLogoLight } from "components/Icons/Icons";
// Custom Components
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import { useHistory } from "react-router-dom";
import routes from "routes.js";
import { ItemContentProfile } from "components/Menu/ItemContentProfile";

export default function HeaderLinks({ secondary, isSuperAdmin }) {
  const history = useHistory();

  const { colorMode } = useColorMode();

  const [user, setUser] = useState();

  const [userProfile, setUserProfile] = useState("");

  useEffect(() => {
    const storedUserProfile = localStorage.getItem("userProfile");
    if (storedUserProfile) {
      const parsedUserProfile = JSON.parse(storedUserProfile);
      setUserProfile(parsedUserProfile);
    }
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        console.error("Error fetching authenticated user:", error);
      });
  }, []);

  <Text color={"teal"} fontWeight={"bold"}>
    {userProfile && userProfile.name}
  </Text>;

  let navbarIcon = useColorModeValue("white", "gray.200");
  // Chakra color mode
  const borderProfileColor = useColorModeValue("white", "transparent");

  let menuBg = useColorModeValue("white", "navy.800");
  if (secondary) {
    navbarIcon = "white";
  }
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      flexWrap="wrap"
    >
      <Flex
        flexDirection={"row"}
        justify="center"
        justifyContent={"center"}
        alignItems="center"
        boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
        border="1.5px solid"
        borderColor={borderProfileColor}
        bg={"white"}
        borderRadius={45}
        pl="1rem"
        ml="1rem"
        mt={{ base: "1rem", md: "0" }}
      >
        <Text color={"#4990c5"} fontWeight={"bold"}>
          {user && user.attributes.name}
        </Text>

        <Menu>
          <MenuButton marginLeft={"0.5rem"}>
            <Avatar color={navbarIcon} w="2.3rem" h="2.3rem" me="0px" />
          </MenuButton>
          <MenuList p="16px 8px" bg={menuBg}>
            <Flex flexDirection="column">
              {/* <MenuItem borderRadius="8px" mb="10px">
                <ItemContentProfile
                  boldInfo="Profile"
                  aSrc={avatar1}
                  aRoute={"/profile"}
                />
              </MenuItem>
              <MenuItem borderRadius="8px" mb="10px">
                <ItemContentProfile
                  boldInfo="Settings"
                  aSrc={avatar2}
                  aRoute={"/profile"}
                />
              </MenuItem> */}
              <MenuItem
                borderRadius="8px"
                onClick={async () => {
                  await Auth.signOut().finally(() => {
                    history.push("/");
                  });
                }}
              >
                <ItemContentProfile boldInfo="Log out" aSrc={avatar3} />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>

      <SidebarResponsive
        hamburgerColor={"white"}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            {colorMode === "dark" ? (
              <ArgonLogoLight w="74px" h="27px" />
            ) : (
              <ArgonLogoDark w="74px" h="27px" />
            )}
          </Stack>
        }
        colorMode={colorMode}
        secondary={secondary}
        routes={routes(isSuperAdmin)}
      />
    </Flex>
  );
}
