// @ts-check
import { Flex } from "@chakra-ui/react";
import { listAuditedAccounts } from "graphql/queries";
import { AuditedAccountsListTable } from "./AuditedAccountsListTable";
import useGraphqlOperation from "hooks/useGraphqlOperation";
import { AuditedAccountsCreateForm } from "./AuditedAccountsCreateForm";
import { useState } from "react";

export function AuditedAccountsPage() {
  const { data, updateData, loadingData } =
    useGraphqlOperation(listAuditedAccounts);
  const [toEdit, setToEdit] = useState(null);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <AuditedAccountsCreateForm
        updateData={updateData}
        loadingData={loadingData}
        toEdit={toEdit}
        setToEdit={setToEdit}
      />
      <AuditedAccountsListTable
        data={data}
        update={updateData}
        setToEdit={setToEdit}
      />
    </Flex>
  );
}
