// @ts-check
import {
  Card,
  Flex,
  FormControl,
  Table,
  Tbody,
  Text,
  Thead,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormSubmitButtons } from "components/Form/FormSubmitButtons";
import { Formik } from "formik";
import { createCryptoSignal, updateCryptoSignal } from "graphql/mutations";
import { notificateSignal } from "notifications/Notificator";
import Swal from "sweetalert2";
import * as Yup from "yup";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useEffect, useRef } from "react";
import { nanoid } from "nanoid";
import useGraphqlOperation from "hooks/useGraphqlOperation";
import { FormInput } from "components/Form/FormInput";
import { FormTextarea } from "components/Form/FormTextarea";
import { Loader } from "components/Loader";
import { PriceValueObject } from "@juandardilag/value-objects/PriceValueObject";
import { FormSelect } from "components/Form/FormSelect";

export function CryptoSignalsCreateForm({
  updateData,
  loadingData,
  toEdit,
  setToEdit,
}) {
  const textColor = useColorModeValue("gray.700", "white");
  const formRef = useRef(null);
  const { op: createSignalOperation } = useGraphqlOperation(createCryptoSignal);
  const { op: updateSignalOperation } = useGraphqlOperation(updateCryptoSignal);

  useEffect(() => {
    if (toEdit) {
      formRef?.current?.setFieldValue("firstToken", toEdit.firstToken);
      formRef?.current?.setFieldValue("secondToken", toEdit.secondToken);
      formRef?.current?.setFieldValue("type", toEdit.operation);
      formRef?.current?.setFieldValue(
        "price",
        new PriceValueObject(toEdit.price).toString(),
      );
      formRef?.current?.setFieldValue("position", toEdit.position);
      formRef?.current?.setFieldValue("title", toEdit.title);
      formRef?.current?.setFieldValue("subtitle", toEdit.subtitle);
    }
  }, [toEdit]);

  return (
    <Flex
      direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
      display="flex"
    >
      <Flex style={{ justifyContent: "center", alignItems: "center" }}>
        <Card
          overflowX={{ sm: "scroll", xl: "hidden" }}
          style={{ width: "40rem", padding: "1rem" }}
          pb="0px"
        >
          <CardHeader p="0px 0px 0px 0px">
            <Flex direction="column" w={{ sm: "100%" }}>
              <Text
                fontSize="xl"
                color={textColor}
                fontWeight="bold"
                pb=".5rem"
              >
                {toEdit ? "Editar" : "Crear"} Señal Crypto
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead></Thead>
              <Tbody>
                <Flex
                  style={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Formik
                    id="formik"
                    innerRef={formRef}
                    initialValues={{
                      title: "",
                      subtitle: "",
                      firstToken: "",
                      secondToken: "",
                      type: "",
                      price: "$0",
                      position: "",
                    }}
                    validationSchema={Yup.object({
                      firstToken: Yup.string().required("Requerido"),
                      secondToken: Yup.string().required("Requerido"),
                      type: Yup.string().required("Requerido"),
                      price: Yup.string().required("Requerido"),
                      position: Yup.string().required("Requerido"),
                      title: Yup.string().required("Requerido"),
                      subtitle: Yup.string().required("Requerido"),
                    })}
                    onSubmit={async (values, { resetForm }) => {
                      try {
                        if (!toEdit) {
                          await notificateSignal({
                            title:
                              "You have a new Signal, please check the app",
                            pair: `${values.firstToken}-${values.secondToken}`,
                          });

                          await createSignalOperation({
                            id: nanoid(),
                            firstToken: values.firstToken,
                            secondToken: values.secondToken,
                            operation: values.type,
                            position: values.position,
                            price: PriceValueObject.fromString(
                              values.price,
                            ).toNumber(),
                            title: values.title,
                            subtitle: values.subtitle,
                          });
                        } else {
                          await notificateSignal({
                            title:
                              "A previous signal was edited, please check the app",
                            pair: `${values.firstToken}-${values.secondToken}`,
                          });

                          await updateSignalOperation({
                            id: toEdit.id,
                            title: values.title,
                            subtitle: values.subtitle,

                            firstToken: values.firstToken,
                            secondToken: values.secondToken,
                            operation: values.type,
                            position: values.position,
                            price: PriceValueObject.fromString(
                              values.price,
                            ).toNumber(),
                          });
                        }

                        Swal.fire({
                          title: "Señal " + (toEdit ? "editada" : "creada"),
                          text: "Los suscriptores han sido notificados",
                          icon: "success",
                          willClose: () => {
                            resetForm();
                            updateData();
                            setToEdit(null);
                          },
                        });
                      } catch (error) {
                        Swal.fire(
                          "Revisa e intenta de nuevo",
                          error.message,
                          "error",
                        );
                      }
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      resetForm,
                      values,
                      setFieldValue,
                    }) => (
                      <>
                        <form onSubmit={handleSubmit}>
                          <FormControl>
                            <FormInput
                              name="firstToken"
                              label="Primer Token"
                              placeholder="Ingrese el token (ej: BTC)"
                              handleChange={handleChange}
                              value={values.firstToken}
                            />
                            <FormInput
                              name="secondToken"
                              label="Segundo Token"
                              placeholder="Ingrese el token (ej: USDT)"
                              handleChange={handleChange}
                              value={values.secondToken}
                            />
                            <FormInput
                              name="price"
                              label="Precio de interés"
                              type="currency"
                              placeholder="1.00420"
                              handleChange={handleChange}
                              onInputChange={(priceStr) =>
                                setFieldValue("price", priceStr)
                              }
                              value={values.price}
                            />
                            <FormSelect
                              name="type"
                              label="Tipo de Orden"
                              placeholder="Seleccione un tipo de orden"
                              handleChange={handleChange}
                              value={values.type}
                              options={[
                                { value: "OPEN", label: "New" },
                                { value: "CLOSE", label: "Close" },
                              ]}
                            />
                            <FormSelect
                              name="position"
                              label="Tipo de Posición"
                              placeholder="Seleccione un tipo de posición"
                              handleChange={handleChange}
                              value={values.position}
                              options={[
                                { value: "BUY", label: "Buy" },
                                { value: "SELL", label: "Sell" },
                              ]}
                            />
                            <FormInput
                              name="title"
                              label="Titulo del análisis"
                              placeholder="Ingrese el titulo del análisis"
                              handleChange={handleChange}
                              value={values.title}
                            />
                            <FormTextarea
                              name="subtitle"
                              label="Descripción del análisis"
                              placeholder="Ingrese la descripción del análisis"
                              handleChange={handleChange}
                              value={values.subtitle}
                            />
                            <FormSubmitButtons
                              disabled={isSubmitting}
                              okLabel={`${toEdit ? "Editar" : "Crear"} `}
                              onReset={() => {
                                resetForm();
                                setToEdit(null);
                              }}
                              onSubmit={handleSubmit}
                            />
                          </FormControl>
                        </form>
                        <Loader loading={isSubmitting || loadingData} />
                      </>
                    )}
                  </Formik>
                </Flex>
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Flex>
    </Flex>
  );
}
