// @ts-check
import {
  Tr,
  Td,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import { Tooltip } from "@chakra-ui/react";
import { ItemContentSignals } from "components/Menu/ItemContentSignals";
import { API } from "aws-amplify";
import { deleteCryptoSignal } from "graphql/mutations";

import Swal from "sweetalert2";
import { AiFillEdit } from "react-icons/ai";
import { PriceValueObject } from "@juandardilag/value-objects/PriceValueObject";

export function CryptoSignalsListTableRow({
  item: cryptoSignal,
  updateData,
  setToEdit,
}) {
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function deleteSignalHandler() {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonColor: "#F56565",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await API.graphql({
            query: deleteCryptoSignal,
            variables: {
              input: {
                id: cryptoSignal.id,
              },
            },
          });
          Swal.fire(
            "Eliminada!",
            "La señal ha sido eliminada.",
            "success",
          ).finally(() => {
            updateData();
          });
        } catch (error) {
          Swal.fire("Error", "La señal no ha sido eliminada.", "error");
        }
      }
    });
  }

  return (
    <Tr>
      <Td
        minWidth={{ sm: "100px" }}
        pl="0px"
        borderColor={borderColor}
        // borderBottom={isLast ? "none" : null}
      >
        <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {(
              cryptoSignal.firstToken +
              "-" +
              cryptoSignal.secondToken
            ).toUpperCase()}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {new PriceValueObject(cryptoSignal.price).toString()}
        </Text>
      </Td>
      <Td borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {new Date(cryptoSignal.createdAt).toLocaleTimeString("es-CO", {
            hour12: false,
          })}
        </Text>
      </Td>
      <Td borderColor={borderColor}>
        <Tooltip
          hasArrow
          label={
            "Fecha de Lanzamiento por parte nuestra: " + cryptoSignal.createdAt
          }
          fontSize="sm"
        >
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {new Date(cryptoSignal.createdAt).toLocaleDateString()}
          </Text>
        </Tooltip>
      </Td>
      <Td borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {cryptoSignal.operation ? cryptoSignal.operation : ""}
        </Text>
      </Td>
      <Td
        borderColor={borderColor}
        // borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Badge
          bg={cryptoSignal.position == "BUY" ? "green.400" : "red.400"}
          color={cryptoSignal.position == "BUY" ? "white" : "white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {cryptoSignal.position == "BUY" ? "Comprar" : "Vender"}
        </Badge>
      </Td>
      <Td borderColor={borderColor}>
        <Menu>
          <MenuButton marginLeft={"1rem"}>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList p="16px 8px">
            <Flex flexDirection="column">
              <MenuItem
                borderRadius="8px"
                mb="10px"
                onClick={() => setToEdit(cryptoSignal)}
              >
                <AiFillEdit size={22} />
                Editar
              </MenuItem>
              <MenuItem
                borderRadius="8px"
                mb="10px"
                onClick={deleteSignalHandler}
              >
                <ItemContentSignals
                  type="delete"
                  onPress={deleteSignalHandler}
                />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}
