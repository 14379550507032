import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import CardHeader from "components/Card/CardHeader";

/**
 * @typedef {Object} TableHeaderProps
 * @property {string} entity - name of the entity to be displayed
 * @property {boolean} [isFemenine] - indicates if the entity is feminine
 * @property {number} total - count of the items to be listed
 */

/**
 * @param {TableHeaderProps} props
 */
export function TableHeader({ entity, isFemenine, total }) {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <CardHeader p="6px 0px 22px 0px">
      <Flex direction="column">
        <Text
          fontSize={{ base: "md", md: "xl" }}
          color={textColor}
          fontWeight="bold"
          pb=".5rem"
        >
          {isFemenine ? `Todas las ${entity}` : `Todos los ${entity}`} ({total})
        </Text>
      </Flex>
    </CardHeader>
  );
}
