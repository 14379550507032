// @ts-check
import {
  Button,
  Card,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Table as ChakraTable,
} from "@chakra-ui/react";
import { TableHeader } from "./TableHeader";
import CardBody from "components/Card/CardBody";
import { HiOutlineRefresh } from "react-icons/hi";

/**
 * @typedef {Object} TableProps
 * @property {string[]} columns - column names of the table
 * @property {any[]} items - items to populate the table
 * @property {any} RowComponent - component to render each row
 * @property {function} updateData - function to update the data of the table
 * @property {string} entity - name of the entity to display in the header
 * @property {boolean} [isFemenine] - indicates if the entity name is feminine
 * @property {function} [setToEdit] - function to set the item to edit
 */

/**
 * Table component
 * @param {TableProps} props - input props
 * @returns {React.ReactElement}
 */
export const Table = ({
  columns,
  items,
  RowComponent,
  updateData,
  entity,
  isFemenine,
  setToEdit,
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Card overflowX={{ sm: "scroll", xl: "scroll" }} my={"2rem"} p={"2rem"}>
      <TableHeader
        entity={entity}
        total={items?.length || 0}
        isFemenine={isFemenine}
      />
      <CardBody>
        <Button onClick={() => updateData()} leftIcon={<HiOutlineRefresh />}>
          Actualizar
        </Button>
        <ChakraTable variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px">
              {columns.map((col) => (
                <Th color="gray.400" borderColor={borderColor}>
                  {col}
                </Th>
              ))}
            </Tr>
          </Thead>
          {items?.length ? (
            <Tbody>
              {items
                .filter((item) => !!item)
                .map((item, i) => {
                  return (
                    <RowComponent
                      key={i}
                      item={item}
                      updateData={updateData}
                      setToEdit={setToEdit}
                    />
                  );
                })}
            </Tbody>
          ) : null}
        </ChakraTable>
      </CardBody>
    </Card>
  );
};
