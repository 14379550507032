// @ts-check
import { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import UserListTableRow from "views/Dashboard/Users/UsersListTableRow";
import { listUsers } from "graphql/queries";
import { Table } from "components/Table/Table";
import { Loader } from "components/Loader";
import useGraphqlOperation from "hooks/useGraphqlOperation";

function UsersListTable() {
  const { data, updateData, loadingData } = useGraphqlOperation(listUsers);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (data)
      setUsers(
        data.listUsers?.items?.filter((item) => !!item && !item._deleted) || [],
      );
  }, [data]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Table
        columns={[
          "ID",
          "Nombre / Email",
          "Fecha de Registro / Telefono",
          "Sesion Activa",
          "Notificaciones",
          "Plan Actual",
          "Fecha de Activación",
          "KYC",
          "¿Ha firmado descargos?",
        ]}
        items={users.sort((a, b) => a.name.localeCompare(b.name))}
        RowComponent={UserListTableRow}
        entity="usuarios"
        updateData={updateData}
      />
      <Loader loading={loadingData} />
    </Flex>
  );
}

export default UsersListTable;
