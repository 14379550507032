// @ts-check
import { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Text,
  Input,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Card,
} from "@chakra-ui/react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { HiOutlineRefresh } from "react-icons/hi";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineClear } from "react-icons/ai";
import { Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { listMotivationalMessages } from "graphql/queries";
import { createMotivationalMessage } from "graphql/mutations";
import TablesTableRowMentalidad from "components/Table/TablesTableRowMentalidad";
import { TableHeader } from "components/Table/Table/TableHeader";
import { notificateMotivationalMessage } from "notifications/Notificator";
import { nanoid } from "nanoid";
import { Loader } from "components/Loader";

function TablesMentalidad() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const formRef = useRef();

  const [mentalidads, setMentalidads] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getAllMentalidads = async () => {
    setIsLoading(true);
    const result = await API.graphql(graphqlOperation(listMotivationalMessages))
      .then((data) => {
        setMentalidads(
          data?.data?.listMotivationalMessages?.items?.filter(
            (item) => !item._deleted,
          ) || [],
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllMentalidads();
  }, []);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Flex
        direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
        display="flex"
      >
        <Flex style={{ justifyContent: "center", alignItems: "center" }}>
          <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
            style={{ width: "40rem", padding: "1rem" }}
            pb="0px"
          >
            <CardHeader p="0px 0px 0px 0px">
              <Flex direction="column" w={{ sm: "100%" }}>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  pb=".5rem"
                >
                  Enviar Mensaje de Salud Mental
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Table variant="simple" color={textColor}>
                <Thead></Thead>
                <Tbody>
                  <Flex
                    style={{
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Formik
                      id="formik"
                      innerRef={formRef}
                      initialValues={{
                        title: "",
                      }}
                      validationSchema={Yup.object({
                        title: Yup.string().required("Requerido"),
                      })}
                      onSubmit={async (values, { resetForm }) => {
                        let payload = {
                          title: values.title,
                        };

                        try {
                          await API.graphql(
                            graphqlOperation(createMotivationalMessage, {
                              input: { id: nanoid(), ...payload },
                            }),
                          );
                          await notificateMotivationalMessage(payload);
                          setIsLoading(false);

                          Swal.fire({
                            title: "Mensaje Creado",
                            text: "Por favor revisa la app",
                            icon: "success",
                            willClose: () => {
                              resetForm();
                              getAllMentalidads();
                            },
                          });
                        } catch (error) {
                          setIsLoading(false);
                          Swal.fire(
                            "Revisa e intenta de nuevo",
                            error.message,
                            "error",
                          );
                          console.log(error);
                        }
                      }}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        values,
                      }) => (
                        <>
                          <form onSubmit={handleSubmit}>
                            <FormControl>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Mensaje
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Contenido del mensaje"
                                  size="lg"
                                  id="form_title"
                                  name="title"
                                  onChange={handleChange}
                                  value={values.title}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="title" />
                                </div>
                              </>

                              <Flex style={{ marginBottom: "2rem" }}>
                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="40%"
                                  h="45"
                                  leftIcon={
                                    <AiOutlineClear color="#FFFFFF" size={21} />
                                  }
                                  backgroundColor={"#3182CE"}
                                  color={"white"}
                                  onClick={() => {
                                    resetForm();
                                  }}
                                >
                                  RESET
                                </Button>

                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="60%"
                                  h="45"
                                  disabled={isSubmitting}
                                  onClick={handleSubmit}
                                  leftIcon={<RiSendPlaneFill />}
                                >
                                  ENVIAR MENSAJE
                                </Button>
                              </Flex>
                            </FormControl>
                          </form>
                          <Loader loading={isLoading || isSubmitting} />
                        </>
                      )}
                    </Formik>
                  </Flex>
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </Flex>
      </Flex>

      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        my={{ base: "2rem", md: "1rem" }}
        p={{ base: "1rem", md: "2rem" }}
        w={{ base: "100%", md: "90%" }}
        mx="auto"
      >
        <TableHeader entity="mensajes" total={mentalidads.length} />
        <CardBody>
          <Table
            variant="simple"
            color={textColor}
            display={{ base: "block", md: "table" }}
          >
            <Thead display={{ base: "none", md: "table-header-group" }}>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => getAllMentalidads()}
                  leftIcon={<HiOutlineRefresh />}
                  display={{ base: "none", md: "flex" }}
                >
                  Actualizar
                </Button>
              </div>
              <Tr my=".8rem" pl="0px">
                <Th color="gray.400" borderColor={borderColor}>
                  ID
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Mensaje
                </Th>

                <Th color="gray.400" borderColor={borderColor}>
                  Acciones
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {mentalidads
                .filter((item) => !!item)
                .map((row, index, arr) => {
                  return (
                    <TablesTableRowMentalidad
                      id={row.id}
                      title={row.title}
                      mentalidad={row}
                      getAllMentalidads={getAllMentalidads}
                      isLast={index === arr.length - 1 ? true : false}
                      key={index}
                    />
                  );
                })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default TablesMentalidad;
